<script setup>
import { useNuxtApp } from '#imports';
import { useUserStore } from '@/stores/user';
import { useCookie, useRoute } from 'nuxt/app';
import { usePolicePopup } from '@/composables/usePolicePopup';

import UiButton from '@/components/ui/button/UiButton.vue';
import { police } from '@/configs/police';

const route = useRoute();
const { openPolicePopup } = usePolicePopup();
const { $tp } = useNuxtApp();
const userStore = useUserStore();

const emit = defineEmits(['selectCookie']);

function selectCookie(value) {
  const adultCookie = useCookie('cookie');

  adultCookie.value = Boolean(value);
  userStore.setCookieState(value);

  emit('selectCookie');
}
</script>
<template>
  <div
    class="cookie-section"
    :class="{ 'cookie-section--white': route.name === 'index' }"
  >
    <div class="cookie-section__info">
      <p class="cookie-section__font cookie-section__font--info">
        {{ $tp('Мы используем') }}

        <span
          role="button"
          type="button"
          @click="openPolicePopup(police.privacyPolicy)"
          >cookies</span
        >

        {{ $tp('для аналитики и правильной работы сайта') }}
      </p>
    </div>

    <div class="cookie-section__action">
      <UiButton
        theme="white"
        class="cookie-section__button"
        text="Понятно"
        @click="selectCookie(true)"
      />

      <UiButton
        v-if="false"
        theme="second-white"
        class="cookie-section__button"
        text="Отклонить"
        @click="selectCookie(false)"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.cookie-section {
  $parent: &;

  display: flex;
  flex-direction: column;
  gap: em(20);
  width: 100%;
  max-width: em(416);
  padding: em(36) em(48) em(24);
  background-color: $color-grey-115;
  backdrop-filter: blur(5px);

  @include media-breakpoint-down(sm) {
    max-width: em(375);
    padding: em(36) em(30) em(24);
    backdrop-filter: blur(30px);
    gap: em(32);
  }

  &__font {
    &--info {
      @include subtitle-s;

      color: $color-white;
      display: inline;

      @include media-breakpoint-down(sm) {
        color: $color-black-100;
      }

      &:deep(span) {
        text-decoration: underline;
        cursor: pointer;

        @include hover {
          text-decoration: none;
        }
      }
    }
  }

  &__info {
    text-align: center;
  }

  &__action {
    display: flex;
    justify-content: center;
    gap: em(16);
  }

  &__button {
    width: calc(50% - #{em(8)});

    @include media-breakpoint-down(sm) {
      color: $color-black-100;
      border-color: $color-black-100;
    }
  }

  &--white {
    background-color: $color-white;
    backdrop-filter: blur(10px);

    #{$parent} {
      &__font {
        &--info {
          color: $color-black-100;
        }
      }

      &__button {
        color: $color-black-100;
        border-color: $color-black-100;
      }
    }
  }
}
</style>
