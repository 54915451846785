<script setup>
import { useNuxtApp } from '#imports';
import { useFeedback } from '@/composables/useFeedback';

import UiButton from '@/components/ui/button/UiButton.vue';
import BackgroundBlock from '@/components/blocks/BackgroundBlock.vue';

const { openFeedbackPopup } = useFeedback();
const { $tp } = useNuxtApp();
</script>
<template>
  <div class="site-unavailable-section">
    <BackgroundBlock theme="fixed" />

    <UiButton
      theme="white-small"
      class="site-unavailable-section__button"
      text="Помощь"
      @click="openFeedbackPopup"
    />

    <div class="site-unavailable-section__body">
      <div class="site-unavailable-section__content">
        <div class="site-unavailable-section__heading">
          <h3
            class="site-unavailable-section__font site-unavailable-section__font--heading"
          >
            {{ $tp('Сайт недоступен') }}
          </h3>
        </div>

        <div class="site-unavailable-section__description">
          <p
            class="site-unavailable-section__font site-unavailable-section__font--description"
          >
            {{ $tp('К сожалению, доступ для вашей учетной записи ограничен.') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.site-unavailable-section {
  display: flex;
  padding: em(80) em(30);

  &__font {
    &--heading {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: em(50);
      line-height: 1.2;
      text-transform: uppercase;
      text-align: center;
      color: $color-white;

      @include media-breakpoint-down(sm) {
        font-size: em(24);
        line-height: 1.3;
      }
    }

    &--description {
      @include subtitle-m;

      color: $color-white;

      @include media-breakpoint-down(sm) {
        @include subtitle-m-mob;
      }
    }
  }

  &__body {
    display: flex;
    margin: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: em(704);
    text-align: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__description {
    max-width: em(420);
    margin-top: em(32);

    @include media-breakpoint-down(sm) {
      margin-top: em(16);
    }
  }

  &__button {
    position: absolute;
    top: em(60);
    left: em(60);
    z-index: 2;
    min-width: em(160);

    @include media-breakpoint-down(lg) {
      min-width: em(116);
    }

    @include media-breakpoint-down(sm) {
      top: em(15);
      right: em(15);
      left: auto;
      min-width: em(104);
    }
  }
}
</style>
