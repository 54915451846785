<script setup>
import { ref, onMounted, onBeforeUnmount } from '#imports';

import { useGlobalStore } from '@/stores/global';
import { useScrollLock } from '@/composables/useScrollLock';

import BackgroundBlock from '@/components/blocks/BackgroundBlock.vue';
import UiProgressDiagram from '@/components/ui/UiProgressDiagram.vue';

const globalStore = useGlobalStore();
const scrollLock = useScrollLock();
const preloaderRef = ref(null);
const percent = ref(null);
const percentMax = 100;

function startPercentChange(start, end, duration) {
  const step = (end - start) / (duration / 10); // шаг изменения значения
  let currentValue = start;

  const intervalId = setInterval(() => {
    currentValue += step;
    if (
      (step > 0 && currentValue >= end) ||
      (step < 0 && currentValue <= end)
    ) {
      currentValue = end;
      clearInterval(intervalId);
      setupEndAnimation();
    }

    percent.value = Math.round(currentValue);
  }, 10); // обновление каждых 10 миллисекунд
}

function setupEndAnimation() {
  setTimeout(() => {
    globalStore.changeShowPreloader(!globalStore.isShowPreloader);
  }, 700);
}
onMounted(() => {
  scrollLock.lock(preloaderRef.value);
});
onBeforeUnmount(() => {
  scrollLock.unlock(preloaderRef.value);
});

defineExpose({ startPercentChange }); // разрешаем обращаться к свойствам данного компонента в родителе
</script>
<template>
  <div ref="preloaderRef" class="c-preloader">
    <BackgroundBlock class="c-preloader__bg" target-class="c-preloader" />

    <div class="c-preloader__wrapper">
      <UiProgressDiagram
        ref="progressRef"
        show-percent
        :percent="percent"
        :max="percentMax"
        class="c-preloader__progress"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.c-preloader {
  position: relative;

  &__wrapper {
    position: absolute;
    overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    margin: auto;
    width: em(264);
    aspect-ratio: 1 / 1;

    @include media-breakpoint-down(lg) {
      width: em(164);
    }
  }

  &__progress {
    width: 100%;
    height: 100%;
  }
}
</style>
