<template>
  <Resize class="layouts-default" :class="className">
    <slot class-name="layouts-default__view" />

    <transition name="fade" mode="out-in">
      <SectionsSiteUnavailable
        v-if="siteUnavailable"
        ref="siteUnavailableRef"
        data-scroll-lock-scrollable
        class="layouts-default__site-unavailable"
      />
    </transition>

    <Transition mode="out-in" name="fade">
      <Cookie
        v-if="showCookie && isAdult"
        data-scroll-lock-scrollable
        class="layouts-default__cookie"
        @select-cookie="hideCookieSection"
      />
    </Transition>

    <Transition mode="out-in" name="preloader" @enter="onEnter">
      <CPreloader
        v-if="globalStore.isShowPreloader"
        ref="preloaderRef"
        class="layouts-default__preloader"
      />
    </Transition>
  </Resize>
</template>

<script setup>
import { ref, computed, watch } from '#imports';
import { useCookie } from 'nuxt/app';
import { USER_ROLES } from '@/configs/user-roles';
import { useUserStore } from '@/stores/user';
import { useGlobalStore } from '@/stores/global';
import { useScrollLock } from '@/composables/useScrollLock';

import Cookie from '@/components/sections/Cookie.vue';
import Resize from '@/components/common/CResize.vue';
import SectionsSiteUnavailable from '@/components/sections/SiteUnavailable.vue';
import CPreloader from '@/components/common/CPreloader.vue';

const userStore = useUserStore();
const globalStore = useGlobalStore();
const scrollLock = useScrollLock();

defineProps({
  className: {
    type: String,
    default: undefined,
  },
});

//<editor-fold desc="SITE_UNAVAILABLE">
const siteUnavailable = ref(false);
const siteUnavailableRef = ref(null);

watch(
  () => userStore.user?.role,
  (value) => {
    if (!value) return;

    if (value === USER_ROLES.BLOCKED) {
      siteUnavailable.value = true;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => siteUnavailable.value,
  () => {
    if (process.server) return;

    if (siteUnavailable.value) scrollLock.lock(siteUnavailableRef.value?.$el);
  },
  {
    immediate: true,
  },
);
//</editor-fold>

//<editor-fold desc="COOKIE">
const showCookie = ref(true);
const isAdult = computed(() => {
  return userStore.isAdult;
});

checkCookieSectionState();

function checkCookieSectionState() {
  const adultCookie = useCookie('cookie');
  const hasCookie = adultCookie.value !== undefined;
  const isAcceptCookie = adultCookie.value;
  const isSelectedCookie = userStore.isSelectedCookie;

  showCookie.value = !hasCookie || (!isAcceptCookie && !isSelectedCookie);
}
function hideCookieSection() {
  showCookie.value = false;
}
//</editor-fold>

//<editor-fold desc="PRELOADER">
const preloaderRef = ref(null);
function onEnter() {
  preloaderRef.value.startPercentChange(0, 100, 1200);
}
//</editor-fold>
</script>

<style scoped lang="scss">
.layouts-default {
  $parent: &;

  display: flex;
  flex-direction: column;

  &:deep(#{$parent}__view) {
    flex-grow: 1;
  }

  &__site-unavailable {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 199;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
  }

  &__cookie {
    position: fixed !important;
    bottom: em(40);
    left: em(40);
    z-index: 99;

    @include media-breakpoint-down(sm) {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__preloader {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}
</style>
