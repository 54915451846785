<script setup>
import { onMounted, ref, watch } from '#imports';

const props = defineProps({
  percent: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  showPercent: {
    type: Boolean,
    default: false,
  },
  units: {
    type: String,
    default: '%',
  },
});

const circle = ref(null);
const text = ref(null);

onMounted(() => {
  setPercentage();
});

watch(
  () => props.percent,
  () => {
    setPercentage();
  },
);
function setPercentage() {
  const length = circle.value.getTotalLength();
  circle.value.style['stroke-dasharray'] = length;
  let percentage = text.value.innerHTML;

  percentage = 100 - addPercent();
  circle.value.style['stroke-dashoffset'] = (length / 100) * percentage;
}
function addPercent() {
  const value = (props.percent * 100) / props.max;
  if (value > 100) return 100;
  if (value < 0) return 0;
  return value;
}
</script>
<template>
  <span class="diagram">
    <span class="diagram__percentage">
      <span
        v-if="showPercent && percent !== null"
        ref="percentage"
        class="diagram__font diagram__font--percentage"
      >
        {{ percent }} {{ units }}
      </span>
    </span>
    <svg version="1.1" viewBox="-2 -2 104 104" class="diagram__icon">
      <path
        class="diagram__bg"
        d="M0,50 A50,50,0 1 1 100,50 A50,50,0 1 1 0,50"
      />
      <path
        ref="circle"
        class="diagram__circle"
        d="M0,50 A50,50,0 1 1 100,50 A50,50,0 1 1 0,50"
      />
      <text
        ref="text"
        class="diagram__percentage"
        transform="rotate(-90 50 50)"
        xml:space="preserve"
        y="59"
        x="50"
      ></text>
    </svg>
  </span>
</template>
<style scoped lang="scss">
.diagram {
  $parent: &;
  $color-circle: var(--color-circle);
  $color-progress: var(--color-progress);

  --color-circle: #{transparebt};
  --color-progress: #{#ffffff};
  --color-text: #{#ffffff};
  --circle-stoke-width: 1px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__font {
    &--percentage {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: em(50);
      line-height: 100%;
      letter-spacing: 0.03em;
      text-align: center;
      color: var(--color-text);
      transition: color 0.5s;
      text-transform: uppercase;

      @include media-breakpoint-down(lg) {
        font-size: em(24);
      }
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
  }

  &__bg {
    fill: transparent;
    stroke: $color-circle;
    stroke-width: var(--circle-stoke-width);
    transition: stroke-width 0.5s;
  }

  &__circle {
    fill: transparent;
    stroke: $color-progress;
    stroke-width: var(--circle-stoke-width);
    transition: stroke-width 0.5s;
  }

  &__icon {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>
